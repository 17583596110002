.aboutus-container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.about-head {
    height: 15rem;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    background-image: url(../Images/Group.png);
    background-size: 100% 100%;
    margin-top: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.about {
    border: 1px solid #B2B2B2;
    padding: .3rem .5rem;
    border-radius: .3rem;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
}

.about .fas {
    margin: 0 .3rem;
    margin-top: .2rem;
}

.about span a{
    color: var(--unnamed-color-f6893b);
}
.about a{
    color: var(--unnamed-color-727272);
}

.about-head h2 {
    width: 100%;
    font-size: 2rem;
    color: var(--unnamed-color-727272);
    font-weight: 500;
   
}

.aboutus-container h1 {
    color: var(--unnamed-color-727272);
    font-size: 2.5rem;
}

.nexgplatforms {
    display: flex;
    flex-wrap: wrap;
    border-radius: 1rem;
    width: 95%;
    padding: 3rem ;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    align-items: center;
   
}

.nexglogo {
    flex: 1 1 10rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nexglogo img {
    width: 80%;
}

.nexgcontent {
    flex: 1 1 30rem;
    text-align: left;
    font-size: 1.1rem;
    padding-left: 2rem;
    
}

.nexgcontent h2{
    color: var(--unnamed-color-727272);
    margin-top: 0;
    font-size: 2rem;
    font-weight: 500;
}

.adv-container {
    border: 1px solid black;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

}

.adv-card {
    border: 1px solid black;
    display: flex;
    column-gap: 2rem;
}

.adv-card img {
    height: 6rem;
}

.card-content {

    text-align: left;
}

.card-content h3 {

    margin-top: 0;
    font-size: 1.5rem;
    color: var(--unnamed-color-727272);
    font-weight: 600;
}

.card-content p {
    margin-top: 0;
}

@media screen and (max-width:450px)
{
    .about-head{
        height: 12rem;
    }
    .about-head h2{
        font-size: 1.5rem;
    }
    .nexgplatforms{
        padding: 1rem;
        padding-right: 2rem;
    }
    .nexglogo img {
        width: 90%;
    }
    .nexgcontent h2{
        font-size: 1.6rem;
    }
    .nexgcontent{
        font-size: 1rem;
    }
}