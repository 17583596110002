.contact-head {
    height: 13rem;
    background: var(--unnamed-color-707070) 0% 0% no-repeat padding-box;
    /* background-image: url(../Images/Group.png); */
    background-size: 100% 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.conatctus-container .contact-head h2 {
    color: var(--unnamed-color-ffffff);
    font-size: 2.5rem;
    font-weight: 500;
}

.conatctus-container h2 {
    color: var(--unnamed-color-727272);
    font-size: 2.2rem;
    font-weight: 500;
}

.conatctus-container p {
    color: var(--unnamed-color-727272);
}

.contactus-form {
    display: flex;
    padding: 1rem 3rem;


}

.contactus-form .contactus-left {
    width: 35%;
    height: 100%;

    margin-left: 1rem;
}

.contactus-form .contactus-left .left-card {
    display: flex;
    column-gap: 1rem;
    margin-top: 1rem;
    height: 100%;


}

.contactus-form .contactus-left .left-card .left-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 5rem;
    background: var(--unnamed-color-ffeddf) 0% 0% no-repeat padding-box;
    border: 2px solid var(--unnamed-color-ffcfac);
    border-radius: 50%;

}

.contactus-form .contactus-left .left-card .left-content {
    text-align: left;
    width: 70%;

}

.contactus-form .contactus-left .left-card .left-content h3 {
    color: var(--unnamed-color-727272);
    margin-top: .5rem;
    margin-bottom: 0;

}

.contactus-form .contactus-left .left-card .left-content p {
    margin-top: .5rem;
    margin-bottom: .2rem;
}

.contactus-right {
    width: 65%;

}

.contactus-right form .inputBox {

    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;

}

.contactus-right form .inputBox .input {

    width: 45%;
    text-align: left;
}

.contactus-right form .inputBox .input span {
    display: block;
    padding: .5rem 0;
    font-size: 1rem;
    color: var(--unnamed-color-7b7b7b);
    font-weight: 500;
}

.contactus-right form .inputBox .input input {
    width: 100%;
    padding: .5rem;
    color: var(--unnamed-color-7b7b7b);
    border: 1px solid var(--unnamed-color-d4d4d4);
    border-radius: 5px;
    outline: none;
}

.contactus-right form .inputBox .text-box {
    text-align: left;

    width: 96%;
}

.contactus-right form .inputBox .text-box span {
    padding: .5rem 0;
    font-size: 1rem;
    color: var(--unnamed-color-7b7b7b);
    font-weight: 500;
    display: block;
}

.contactus-right form .inputBox .text-box textarea {
    width: 98%;
    padding: .5rem;
    color: var(--unnamed-color-7b7b7b);
    border: 1px solid var(--unnamed-color-d4d4d4);
    border-radius: 5px;
    outline: none;
}

.checkbox {
    margin-top: .9rem;
    text-align: left;
    color: var(--unnamed-color-7b7b7b);
}

.checkbox a {
    color: var(--unnamed-color-f6893b);
}

.contactus-right form .login {
    text-align: left;
    padding: 1rem .1rem;
}

.contactus-right form .login button {
    width: 30%;
    height: 2.3rem;
    background: var(--unnamed-color-f6893b) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-d4d4d4);
    border-radius: 5px;
    color: var(--unnamed-color-ffffff);
    cursor: pointer;
}

@media screen and (max-width:821px) {
    .contactus-form {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .contactus-form .contactus-left {
        width: 100%;
    }

    .contactus-right {
        width: 100%;
    }

    .contactus-form .contactus-left .left-card .left-img {
        width: 15%;
    }

}

@media screen and (max-width:450px) {
    .contactus-form {
        padding: 1rem 2rem;
        padding-right: 3rem;

    }

    .conatctus-container .contact-head h2 {
        font-size: 2rem;
    }

    .conatctus-container h2 {
        font-size: 1.8rem;
    }

    .conatctus-container p {
        padding: 0 1rem;
    }

    .contactus-right form .inputBox .input {
        width: 100%;
    }

    .contactus-form .contactus-left {
        margin-left: 0;
    }

    .contactus-form .contactus-left .left-card .left-img {
        width: 30%;
    }

    .contactus-form .contactus-left .left-card .left-content h3 {
        margin-left: 1rem;
    }
}