.comingsoon {
    height: 50vh;
}

.comingsoon img {
    width: 40%;
    height: 100%;
}

@media screen and (max-width:450px) {
    .comingsoon img {
        width: 90%;
        height: 50%;
    }

    .comingsoon {
        height: 100%;
    }
}