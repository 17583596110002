@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@500&family=Kanit:wght@500&family=Kdam+Thmor+Pro&family=Lato&family=Montserrat:ital,wght@0,300;0,400;0,500;1,200;1,300;1,400&family=Mulish:ital,wght@0,200;0,400;1,200&family=Poppins:wght@300;400;500&family=Roboto:ital,wght@1,300&family=Titillium+Web:wght@900&family=Work+Sans:ital@0;1&display=swap');

.App {
  text-align: center;
  overflow-x: hidden;
}

html {
  scroll-padding-top: 5.5rem;
  scroll-behavior: smooth;

}

body {
  font-family: 'Lato', sans-serif;
}

:root {

  /* Colors: */
  --unnamed-color-ffeddf: #FFEDDF;
  --unnamed-color-707070: #707070;
  --unnamed-color-727272: #727272;
  --unnamed-color-ff0000: #FF0000;
  --unnamed-color-7b7b7b: #7B7B7B;
  --unnamed-color-ffcfac: #FFCFAC;
  --unnamed-color-e9e9e9: #E9E9E9;
  --unnamed-color-bfbfbf: #BFBFBF;
  --unnamed-color-d4d4d4: #D4D4D4;
  --unnamed-color-222222: #222222;
  --unnamed-color-ffffff: #FFFFFF;
  --unnamed-color-f6893b: #F6893B;
  --unnamed-color-333333: #333333;

}

a {
  text-decoration: none;
}