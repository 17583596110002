.saddle-right {
    flex: 1 1 40rem;
    padding-bottom: 1rem;

}

.saddles_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 1.5rem;
    width: 100%;

}

.saddle_card {

    height: 10rem;
    border-radius: .5rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-bottom: 1rem;
    width: 14rem;
    height: 10rem;
}

.saddle_card h3 {

    margin-top: .5rem;
}

.saddle_card:hover {
    scale: 1.1;
    transition: all .5s ease;
}

.saddle-img {
    width: 100%;
    height: 80%;
    position: relative;

}

.watermark {
    position: absolute;
    top: .5rem;
    left: 4rem;
    rotate: -45deg;

}

.wm {
    top: 2.5rem;
    left: 3rem;
}

.watermark p {
    border-radius: 2rem;
    padding: .2rem;
    color: #E9E9E9;
    opacity: .6;

}

.saddle-img img {

    width: 80%;
    height: 90%;
    position: relative;
}