.home-container {
    display: flex;
    align-items: center;
    margin-top: .4rem;
    padding: 0rem 4rem;
    flex-wrap: wrap;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    opacity: 1;
    gap: 1rem;
    height: 100%;

}

.home-left {
    flex: 1 1 30rem;
    text-align: left;
    padding-left: 1rem;



}

.home-left h3 {
    color: var(--unnamed-color-727272);
    text-align: left;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0px;
    color: #727272;
    opacity: 1;
}

.home-left h1 {
    color: var(--unnamed-color-727272);
    text-align: left;
    font-size: 2.1rem;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    width: 80%;
}

.home-left h4 {
    color: var(--unnamed-color-727272);
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0px;
    width: 90%;
    text-align: justify;

}

.home-left h4 span {
    color: var(--unnamed-color-f6893b);
}

.head-btn .open {
    background: var(--unnamed-color-f6893b);
    color: white;
    padding: .5rem 1rem;
    border-radius: .2rem;
    border: none;
    cursor: pointer;
}

.head-btn .open:hover {
    border: 1px solid var(--unnamed-color-f6893b);
    background-color: var(--unnamed-color-ffffff);
    color: var(--unnamed-color-f6893b);
    transition: all .5s ease;
}

.head-btn .work {
    padding: .5rem 1rem;
    border-radius: .2rem;
    border: 1px solid var(--unnamed-color-f6893b);
    background-color: var(--unnamed-color-ffffff);
    color: var(--unnamed-color-f6893b);
    margin-left: 1rem;
    cursor: pointer;
}

.head-btn .work:hover {
    background: var(--unnamed-color-f6893b);
    color: white;
}

.home-right {
    flex: 1 1 30rem;
    height: 90%;
    display: flex;
    justify-content: center;
    padding: 2rem;


}

.home-right img {
    width: 80%;
    height: 80%;
}

.home-right-container {
    width: 65%;
    height: 90%;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000017;
    border-radius: 20px;
    opacity: 1;
    padding: 1rem;


}

.home-right-container .send {
    background: #FFF0E5 0% 0% no-repeat padding-box;
    border: 1px solid #FBDAC3;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem;
}

.home-right-container .send .money {
    width: 28%;
    height: 2.7rem;
    font-size: .9rem;
    border: .1rem solid var(--bg);
    outline: .1rem solid var(--bg);
    border-radius: .3rem;
    color: var(--unnamed-color-727272);
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ffcfac);
}

.home-right-container .send p {
    color: var(--unnamed-color-727272);
}

.home-right-container .send p span {
    color: var(--unnamed-color-f6893b);
    font-size: 1.2rem;
}

.home-right-container button {
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
    background: var(--unnamed-color-f6893b) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    color: var(--unnamed-color-ffffff);
    cursor: pointer;
    font-size: 1.1rem;
}

.middle {
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
}

.first {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid black; */
}

.first .line {
    height: 1.7rem;
    width: .05rem;
    background: var(--unnamed-color-f6893b) 0% 0% no-repeat padding-box;
}

.first .circle {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: .12rem solid var(--unnamed-color-f6893b);
    border-radius: 50%;
    display: flex;
    color: var(--unnamed-color-f6893b);
    height: .7rem;
    width: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: .2rem;

}

.second {
    /* border: 1px solid black; */
}

.second ul,
.third ul {
    /* border: 1px solid black; */
    margin-top: 1.6rem;
    text-align: left;
    list-style: none;
    padding-left: .5rem;
    font-size: .9rem;
}

.third {
    margin-left: 2rem;
}

.third ul:nth-child(even) {
    font-weight: bold;
}

.second ul:nth-child(even) {
    font-weight: bold;
}

.get h4 {
    color: var(--unnamed-color-727272);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    opacity: 1;
}

.get h1 {
    color: var(--unnamed-color-727272);
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}

/* get section */
.sending-money {
    display: flex;
    flex-direction: row;
    padding: 0rem 3rem;
    justify-content: center;
    flex-wrap: wrap;
}

.item1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 18%;


}

.item-img {
    width: 35%;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--unnamed-color-ffeddf) 0% 0% no-repeat padding-box;
    border: 2px solid var(--unnamed-color-ffcfac);
}

.item-img img {
    width: 3rem;
    height: 3rem;
}

.item1 h3 {
    color: var(--unnamed-color-727272);
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

.item1 p {
    color: var(--unnamed-color-7b7b7b);
    margin-top: 0;
    font-size: .9rem;
}

.item2 img {
    /* border: 1px solid black; */
    width: 100%;
    height: .7rem;
}

.item2 {
    /* border: 1px solid black; */
    height: 5rem;
    margin-top: 2rem;
}

@media screen and (max-width:821px) {
    .item1 {
        width: 30%;
    }
}

@media screen and (max-width:450px) {
    .home-container {
        padding: 1rem 2rem;
    }

    .home-left {
        width: 100%;
    }

    .home-left h3 {
        font-size: 1rem;
    }

    .home-left h1 {
        font-size: 1.6rem;
    }

    .home-left h4 {
        font-size: .9rem;
    }

    .head-btn {

        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 1rem;
    }

    .head-btn .work {
        width: 11rem;
        margin-right: .8rem;
    }

    .home-right-container {
        width: 100%;
        height: 100%;
    }

    .get {
        padding: 1rem;
    }

    .get h4 {
        font-size: 1.1rem;
    }

    .get h1 {
        font-size: 1.8rem;
    }

    .item2 {
        display: none;
    }

    .item1 {
        width: 100%;
    }

    .sending-money {

        padding: 0rem 2rem;
        gap: 1rem;
    }



}



/* get section */