.footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    opacity: 1;
    padding-top: 0;
    margin-top: 2rem;
    justify-content: center;
}

.footer-container {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 2rem 4rem;
    padding-top: 0;
}

.footer .item {
    border-top: 4px solid var(--unnamed-color-727272);
    text-align: left;

}

.it {
    display: flex;
    gap: 1.5rem;
}

.w15 {
    margin-left: -4rem;
}



.footer .item img {
    margin-top: 1rem;
    width: 50%;
    height: 50%;
}

.footer .item h2 {
    color: var(--unnamed-color-222222);
    font-size: 1.5rem;
    font-weight: 500;

}

.footer .item a {
    display: block;
    color: var(--unnamed-color-727272);
    margin-bottom: .5rem;
}

.item .footer-img {
    display: flex;
    margin-bottom: .5rem;

}

.item .footer-img img {
    width: 1.5rem;
    height: 1.6rem;
    margin-right: .5rem;

    margin-top: .3rem;
}

.item .footer-img a {
    margin-top: .2rem;
}

.footer-img p {
    color: var(--unnamed-color-222222);
    margin: 0;
}

.footer-middle {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    padding: 1rem;
    background: var(--unnamed-color-e9e9e9) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-bfbfbf);
    border-radius: 10px;
    margin-top: 1rem;
}

.footer-middle img {
    width: 8rem;
    margin-top: .6rem;
}

.footer-middle a {
    color: var(--unnamed-color-222222);
    font-weight: bolder;
}

.copyright {
    padding: 1rem 0;
    color: var(--unnamed-color-727272);
    font-size: 1rem;
}

.footer-container p {
    color: var(--unnamed-color-727272);
}

@media screen and (max-width:450px) {
    .footer-container {
        padding: 0rem 2.5rem;
    }

    .footer-middle {
        flex-direction: column;
    }

    .footer-middle a {
        padding: .5rem;
    }

    .footer .item {
        border-top: 0;
    }

    .footer .item img {
        height: 75%;
    }

    .it {
        flex-direction: column;
        gap: 0;
    }

    .w15 {
        margin-left: 0;
    }
}