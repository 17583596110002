.western_container {
    padding: 0 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 1.5rem;


}

.western_card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    height: 17rem;
    padding: 1rem;
    padding-bottom: 0;
    border-radius: .5rem;
    position: relative;
    cursor: pointer;
}

.western_card:hover {
    scale: 1.15;
    transition: all .5s ease;
}

.western_card h6 {
    /* border: 1px solid black; */
    margin-top: .5rem;
    font-size: 1rem;
    color: #F6893B;
}

.western_card img {
    width: 100%;
    height: 80%;
    /* border: 1px solid black; */
}