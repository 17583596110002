.privacy-container {
    padding: 0 10%;
}

.privacy-container h1 {
    color: var(--unnamed-color-f6893b);
    font-weight: 600;
}

.privacy-container h2 {
    color: var(--unnamed-color-f6893b);
    font-weight: 600;
    font-size: 1.8rem;
}

.privacy-container h2,
.privacy-container p {
    text-align: left;
}

.privacy-container li {
    text-align: left;
    margin-top: 0;
}

@media screen and (max-width:450px)
{
    .privacy-container h1{
        font-size: 1.8rem;
    } 
    .privacy-container h2{
        font-size: 1.5rem;
    }
}