nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--black);
  z-index: 99;
  padding: 0 3rem;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 6px #00000017;
  opacity: 1;
  position: relative;
  width: 100%;
  margin: 0;
  height: 4rem;

}

.none {
  display: none;
}

.list:hover .dropdown-menu {
  display: flex;

}

.list {
  cursor: pointer;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.list1 {
  height: auto;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  list-style: none;
  bottom: 0rem;
  transform: translateY(100%);
  padding: 0;
  width: max-content;
  display: none;
  transition: all 0.3s ease;
  z-index: 100;
  left: 1;


}

.list .dropdown-menu li {
  padding: .5rem 1rem;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #E9E9E9;
}

.logo {

  width: 5%;
  height: 4rem;
}

.logo img {
  width: 100%;
  height: 100%;
}

.personal {
  display: flex;
  justify-content: flex-start;
  border: 1px solid var(--unnamed-color-bfbfbf);
  border-radius: 5px;

}

.personal li {
  border-right: 1px solid var(--unnamed-color-bfbfbf);
  padding: .3rem .5rem;
}

.personal li a,
.features li a {
  text-decoration: none;
  color: var(--unnamed-color-222222);
  font-size: .9rem;
  font-weight: 500;
}

.personal li a:hover,
.features li a:hover {
  color: var(--unnamed-color-f6893b);
}

.personal li a.active,
.features li a.active {
  color: var(--unnamed-color-f6893b);
}

.features li {
  color: var(--unnamed-color-222222);
  font-size: .9rem;
  font-weight: 500;
  cursor: pointer;
  padding: .5rem;

  padding: 1.4rem;

}

.features li:hover {
  color: var(--unnamed-color-f6893b);
}



.features li i {
  font-size: 1rem;
  color: var(--unnamed-color-f6893b);
  margin-left: .5rem;
}

.f1:hover .modal {
  display: block;
  transition: all 1s ease-in-out;
}



.modal h3 {
  color: var(--unnamed-color-727272);
  font-size: 1.2rem;
  margin-bottom: 2rem;
}



.features {
  display: flex;
  justify-content: space-around;
  width: 65%;

}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;

}

.features li .login {
  background: var(--unnamed-color-f6893b);
  color: white;
  padding: .3rem 1rem;
  border-radius: .2rem;
  padding-bottom: .4rem;
}

.features li .login:hover {
  border: 1px solid var(--unnamed-color-f6893b);
  background-color: var(--unnamed-color-ffffff);
  color: var(--unnamed-color-f6893b);
}

.features li .login.active {
  border: 1px solid var(--unnamed-color-f6893b);
  background-color: var(--unnamed-color-ffffff);
  color: var(--unnamed-color-f6893b);
}

.register {
  padding: .3rem 1rem;
  border-radius: .2rem;
  padding-bottom: .4rem;
  border: 1px solid var(--unnamed-color-f6893b);
  background-color: var(--unnamed-color-ffffff);
  color: var(--unnamed-color-222222);
}



.menu-icon {
  display: none;
}

@media screen and (max-width:850px) {
  nav {
    height: 4.5rem;
    width: 87%;

  }



  .logo {
    width: 20%;
  }

  .menu-icon {
    display: block;
  }



  .menu-list {
    flex-direction: column;
    background: white;
    position: absolute;
    width: 100%;
    padding: 1rem;
    right: 0;
    top: 3.6rem;
    transition: all 0.5s ease-in-out;

  }

  .menu-list .fas {
    cursor: pointer;

  }

  .menu-icon .fa-times {
    transform: rotate(360deg);
    transition: all 0.2s ease-in-out;
    font-size: 1.5rem;
  }

  .menu-icon .fa-bars {
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
    font-size: 1.5rem;
  }

  .menu-list.close {

    right: -110%;
    /* display: none; */
    transition: all .5s ease-in-out;
  }

  .features {
    display: flex;
    flex-direction: column;
    height: 100%;

    width: 90%;
  }

  .features li {
    padding: .5rem;
  }

  .features li .login {
    width: 40%;
  }

  .register {
    margin-top: 2rem;
  }
}

@media screen and (max-width:500px) {
  nav {
    height: 3.5rem;
    padding: 0 1.5rem;
  }

  .logo {
    width: 20%;
    height: 2rem;
  }

  .menu-list {
    top: 2.5rem;
  }
}